import React, { useState } from "react";
// bootstrap
import Form from "react-bootstrap/Form";
// hooks
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers";
import { useHistory, useLocation, Link, Redirect } from "react-router-dom";
// custom components
import { Row, Col } from "react-bootstrap";
import SubmitButton from "../StyledComponents/SubmitButton";
import SmLinkButton from "../StyledComponents/smLinkButton";
import LabelledInput from "../LabelledInput";

import { signIn } from "../../api/auth";
import { SignInSchema } from "./validation";

const SignIn = ({ setUser, user, setPatientList }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [credentialError, setCredentialError] = useState(false);
  const location = useLocation();
  const { from } = location.state || { from: { pathname: "/" } };

  // create a hook for the form, passing in an object of configs

  const { register, handleSubmit, errors, formState, setError } = useForm({
    resolver: yupResolver(SignInSchema),
  });
  const { touched } = formState;
  const onSubmit = async (data) => {
    try {
      setIsLoading(true);
      const res = await signIn(data);
      setUser(res.data);
      // We need a way to set patient state in addition to user state around login time
      if (res.data.userType === "caregiver") history.replace("/patientControl");
      else history.replace(from);
    } catch (err) {
      if (err.message.includes("409")) {
        alert("You are already logged in, please refresh the page.");
        setIsLoading(false);
      } else {
        setError("email", {
          type: "badCredentials",
          message: "Email or Password is incorrect",
        });
        setError("password",
          {
            type: "badCredentials",
            message: "",
          });
        setCredentialError(true);
        setIsLoading(false);
      }
    }
  };

  if (user) {
    return (
      <Redirect
        to={{
          pathname: "/",
        }}
      />
    );
  }

  return (
    <Row className="d-flex justify-content-center">
      <Col xs={10} md={6}>
        <Form
          autoComplete="on"
          method="POST"
          className="mt-2"
          style={{ margin: "0 auto" }}
          onSubmit={handleSubmit(onSubmit)}
        >
          <LabelledInput
            labelAs="h6"
            name="email"
            autoComplete="email"
            inputRef={register}
            errors={errors}
            touched={touched}
          />

          <LabelledInput
            labelAs="h6"
            name="password"
            type="password"
            autoComplete="current-password"
            inputRef={register}
            errors={errors}
            touched={touched}
          />

          <SubmitButton disabled={isLoading} className="w-100" type="submit">
            Login
          </SubmitButton>
          <Form.Row>
            {credentialError ? (
              <div>
                <div style={{ marginBottom: "15px" }}>
                  Oops! that login doesn&apos;t seem to be right.
                </div>
                <div>
                  Forgot password? click{" "}
                  <SmLinkButton>
                    <Link to="/resetPassword1">HERE</Link>
                  </SmLinkButton>
                </div>
                <div>
                  New user? register{" "}
                  <SmLinkButton>
                    <Link to="/register">HERE</Link>
                  </SmLinkButton>
                </div>
              </div>
            ) : (
              <>
                <div style={{ marginBottom: "15px", marginRight: "25px" }}>
                  <Link to="/register">
                    Don&apos;t have an account? Create one today!
                  </Link>
                </div>
                <div style={{ marginBottom: "15px" }}>
                  <Link to="/resetPassword1">Reset Password</Link>
                </div>
              </>
            )}
          </Form.Row>
        </Form>
      </Col>
    </Row>
  );
};

export default SignIn;
